<template>
    <v-card class="personal-development-plan pt-6">
        <div
            v-if="postInfo.image"
            class="d-flex justify-center mb-4"
        >
            <img
                :src="postInfo.image"
                height="100px"
            />
        </div>

        <h2
            v-if="postInfo.title"
            class="title-post"
            v-text="postInfo.title"
        />

        <div
            v-if="postInfo.body"
            class="pb-4"
        >
            <span
                class="ql-editor pa-0"
                v-html="postInfo.body"
            />
        </div>

        <!-- Create/Edit Personal development plan -->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <ol>
                        <li
                            v-for="ansPosition in COUNT_ANSWER"
                            :key="ansPosition"
                            cols="12"
                        >
                            <span
                                class="subtitle"
                                v-text="questions[`question${ansPosition}`]"
                            />
                            <v-text-field
                                v-model="editedItem[`ans${ansPosition}`].value"
                                class="purple-input mb-2"
                                :disabled="isOnlyView"
                                hide-details
                                single-line
                                dense
                            />
                        </li>
                    </ol>

                    <!--  <v-row>
                        <div class="d-flex flex-wrap justify-space-around w-full"></div>
                    </v-row> -->
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import rules from '@/helpers/rulesValidate';
import { mapGetters, mapActions } from 'vuex';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

const COUNT_ANSWER = 4;

export default {
    data: function () {
        return {
            gameId: null,
            rules: {
                ...rules
            },
            item: null,
            COUNT_ANSWER,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            questions: {}
        };
    },
    computed: {
        ...mapGetters('languages', ['getLang', 'getLanguages']),
        ...mapGetters('cache', ['getPosts']),

        postInfo() {
            const url = 'personal-development-plan';
            let currentPost = this.getPosts.find(post => post.attributes.url === url);
            if (!currentPost) {
                currentPost = {
                    attributes: {
                        title2: '',
                        body: '',
                        image2: ''
                    }
                };
            }

            const { title2: title, body, image2 } = currentPost.attributes;
            const image = image2
                ? `${process.env.VUE_APP_API_ENDPOINT}media/imagesSimple?filename=posts/images/${image2}`
                : '';

            return { title, body, image };
        }
    },
    async created() {},

    async mounted() {
        await this.fetchPeriodsDataUser();
    },

    methods: {
        getEditedItem(itemInit = null) {
            const item = itemInit;

            var empty = !item;

            const editedItem = {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.name,
                    error: ''
                }
            };

            for (let position = 1; position <= COUNT_ANSWER; position++) {
                const currentAns = `ans${position}`;
                editedItem[currentAns] = {
                    value: empty ? '' : item[currentAns],
                    error: ''
                };
            }

            return editedItem;
        },

        async save() {
            this.$store.dispatch('loading/start');

            const formData = new FormData();

            formData.append('name', this.editedItem.name.value ?? '');

            for (let position = 1; position <= COUNT_ANSWER; position++) {
                const currentAns = `ans${position}`;
                formData.append(currentAns, this.editedItem[currentAns].value ?? '');
            }

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            try {
                const result = await EvaluationsAPIService.updatePeriodsDataUser({ urlAppend, formData });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterSave(result.data.periodDataUser);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }

            this.$store.dispatch('loading/end');
        },

        afterSave(item) {
            this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);
        },

        async fetchPeriodsDataUser() {
            try {
                this.$store.dispatch('loading/start');

                const result = await EvaluationsAPIService.getPeriodsDataUser({ id: 'last' });

                this.item = result.data.periodDataUser;
                this.editedItem = this.getEditedItem(this.item);

                this.questions = result.data.questions;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }

            this.$store.dispatch('loading/end');
        }
    }
};
</script>

<style lang="scss" scoped>
.personal-development-plan {
    li {
        padding-left: 0.835rem !important;
        margin-bottom: 2rem;
        &::marker {
            color: #ffc211 !important;
        }
    }

    @media (max-width: 600px) {
        .v-form {
            & > .container {
                padding: 0;
            }
        }
        li {
            padding-left: 0.2rem !important;
        }
    }
}
</style>
